import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import Section from '../../components/Common/Section'
import Container from '../../components/Common/Container'
import Typography from '../../components/Common/Typography'
import { Button } from '../../components/Common/Button'

import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'

import * as s from './NotFound.module.scss'

const NotFoundPage: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  const { t, getPath } = useTranslations()
  const { region } = React.useContext(GlobalContext)

  return (
    <>
      <SEO title={`404: ${t('Page not found')}`} />
      <Section className={s.section}>
        <Container>
          <div className={s.content}>
            <div style={{ maxWidth: 582 }}>
              <Typography variant="h1" color="blue" size={80}>
                404
              </Typography>
              <Typography variant="h2" size={40} mb={32}>
                {t('Page not found')}
              </Typography>
              <Typography variant="body1" size={18} mb={32}>
                {t('Page not found description')}
              </Typography>
            </div>
            <div>
              <GatsbyImage
                image={
                  images[`image_${region}`].childImageSharp.gatsbyImageData
                }
                alt={t('Page not found')}
                title={t('Page not found')}
              />
            </div>
          </div>
          <Button
            className={s.button}
            size="large"
            style={{ bottom: region === AppRegionEnum.Ru ? '2%' : '-7%' }}
            onClick={() => navigate(getPath('/'))}
          >
            {t('Back to main')}
          </Button>
        </Container>
      </Section>
    </>
  )
}

export default NotFoundPage

const imagesQuery = graphql`
  query {
    image_ru: file(relativePath: { eq: "signal_404.png" }) {
      childImageSharp {
        gatsbyImageData(width: 497, placeholder: BLURRED)
      }
    }
    image_us: file(relativePath: { eq: "signax_404.png" }) {
      childImageSharp {
        gatsbyImageData(width: 497, placeholder: BLURRED)
      }
    }
  }
`
